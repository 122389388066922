import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Modal } from "antd";
import styled from "styled-components";
import { attachments as testId } from "../../config/testIds";
import { IoAddCircle } from "react-icons/io5";
import dekra from "../../style/dekra";

import { fileToBase64 } from "../../utils/fileUtils";
import {
  AttachmentFile,
  PracticeAttachment,
} from "../../redux/apiSpecifications/apiCrud";
import AttachmentPractice from "./AttachmentPractice";
import { useLazyDownloadAttachmentZipByIdQuery, useLazyDownloadAttachmentByIdQuery } from "../../redux/apiSpecifications/apiDocs";
import { useAuthorization } from "../../hooks/useAuthorization";
import { addMimeType } from "../../utils/base64";
import { useLazyDownloadCustomerDocumentQuery } from "../../redux/apiSpecifications/apiFesf";

const AttachmentsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-upload-list-item-actions {
    a {
      display: none;
    }
  }

  .type-name {
    margin-top: 1em;
    text-transform: uppercase;
    font-size: 0.9em;
  }
`;

const FilesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const AddButton = styled(IoAddCircle)`
  color: ${dekra.primaryColor};
  cursor: pointer;
  font-size: 2.5em;
`;

const PreviewWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4em 2em;
  height: 80vh;
`;

export const UploadDocumentsAction = "localhost";

interface IAttachmentsPracticeProps {
  dataTestId?: string | undefined;
  onAddAttachment: (attachment: PracticeAttachment) => void;
  onCancel?: () => void;
  onRemoveAttachment: (attachmentIndex: number) => void;
  attachments: PracticeAttachment[] | undefined;
  acceptedFormats: any | undefined;
  maxFiles: number | undefined;
  showFilename?: boolean;
  typeName?: string | undefined | null;
  readonly?: boolean | undefined;
  autopen?: boolean | undefined;
  documentTypeCode?: string | undefined;
}

const AttachmentsPractice = ({
  dataTestId,
  onAddAttachment,
  onRemoveAttachment,
  attachments: _attachments,
  acceptedFormats,
  maxFiles,
  showFilename,
  typeName,
  readonly,
  autopen,
  documentTypeCode,
}: IAttachmentsPracticeProps) => {
  const { t } = useTranslation();
  const [filePreview, setFilePreview] = useState({ index: 0, open: false });

  const refAddDocumentImg = useRef<HTMLInputElement | null>(null);

  const [attachments, setAttachments] = useState<
    PracticeAttachment[] | undefined
  >(_attachments);

  // const refreshAttachements = _attachments?.length !== attachments?.length;
  useEffect(() => {
    setAttachments(_attachments);
  }, [_attachments]);

  const [isLoading, setIsLoading] = useState(false);

  const { getAuthorization } = useAuthorization();

  const [downloadAttachment] = useLazyDownloadAttachmentByIdQuery();
  const [downloadAttachmentZipById] = useLazyDownloadAttachmentZipByIdQuery();
  
  const [downloadCustomerAttachment] = useLazyDownloadCustomerDocumentQuery();

  useEffect(() => {
    if (!refAddDocumentImg) return;

    if (autopen) refAddDocumentImg.current?.click();
  }, [refAddDocumentImg]);

  const handleOnPreviewFile = async (
    index: number,
    justDownload: boolean | undefined = false
  ) => {
    // load file content
    if ((attachments![index].base64File?.length || 0) < 100) {
      // bae64File contains mimetype even if the file is empty
      setIsLoading(true);

      const authorization = await getAuthorization();
      const condition = attachments![index].name?.includes("link");

      if (!condition) {
        if (documentTypeCode === "ARCH") {
          const response = await downloadAttachmentZipById({
            authorization,
            id: attachments![index].id!,
          });
  
          if (response.isSuccess && response.data) {
            const attachment = response.data as PracticeAttachment;
            const updatedAttachments = attachments!.map((a, i) =>
              i !== index
                ? a
                : {
                    ...attachment,
                    base64File: addMimeType(
                      attachment.base64File!,
                      attachment.originalName!
                    ),
                  }
            );
            setAttachments(updatedAttachments);
          }
        } else {
          const response = await downloadAttachment({
            authorization,
            id: attachments![index].id!,
          });

          if (response.isSuccess && response.data) {
            const attachment = response.data as PracticeAttachment;
            const updatedAttachments = attachments!.map((a, i) =>
              i !== index
                ? a
                : {
                    ...attachment,
                    base64File: addMimeType(
                      attachment.base64File!,
                      attachment.originalName!
                    ),
                  }
            );
            setAttachments(updatedAttachments);
          }
        }
      } else {
        const response = await downloadCustomerAttachment({
          authorization,
          id: attachments![index].id!,
        });
        if (response.isSuccess && response.data) {
          const attachmentFile = response.data as AttachmentFile;
          const attachment = {
            id: attachmentFile.id,
            name: attachmentFile.name,
            originalName: attachmentFile.originalName,
            documentBasePath: null,
            base64File: attachmentFile.base64File,
          };
          const updatedAttachments = attachments!.map((a, i) =>
            i !== index
              ? a
              : {
                  ...attachment,
                  base64File: addMimeType(
                    attachment.base64File!,
                    attachment.originalName!
                  ),
                }
          );
          setAttachments(updatedAttachments);
        }
      }
      setIsLoading(false);
    }

    if (!justDownload) setFilePreview({ index, open: true });
  };

  const handleOnDownloadFile = async (index: number) => {
    await handleOnPreviewFile(index, true);
  };

  const handleOnClosePreviewFile = () => {
    setFilePreview({ index: -1, open: false });
  };

  const handleRemoveAttachment = (index: number) => {
    onRemoveAttachment(index);
  };

  const renderFilePreview = () => {
    if (!attachments || attachments[filePreview.index] == null) return;

    const attachment = attachments[filePreview.index];
    const extention = attachment.name?.split(".").pop();

    return extention === "pdf" ? (
      <embed
        src={attachment.base64File}
        style={{ width: "100%", height: "100%" }}
      />
    ) : (
      <img src={attachment.base64File} style={{ maxWidth: "100%" }} />
    );
  };

  const handleAddAttachment = async (e: any) => {
    if (!e) return;
    const selectedFile = e.target.files[0];

    if (!selectedFile) return;

    const fileContent = await fileToBase64(selectedFile);

    onAddAttachment({
      id: 0,
      name: selectedFile.name,
      originalName: selectedFile.name,
      base64File: fileContent,
    } as PracticeAttachment);
  };

  return (
    <AttachmentsContainer data-testid={testId.container}>
      <FilesContainer>
        {attachments?.map((attachment: PracticeAttachment, i) => (
          <AttachmentPractice
            key={i}
            attachment={attachment}
            onDelete={() => handleRemoveAttachment(i)}
            onPreview={() => handleOnPreviewFile(i)}
            onDownload={() => handleOnDownloadFile(i)}
            isLoading={isLoading && filePreview.index === i}
            showFilename={showFilename || (maxFiles || 0) > 1}
            readonly={readonly}
          />
        ))}

        <Tooltip title={t("subject-document-attachments-tooltip")}>
          <input
            ref={refAddDocumentImg}
            type="file"
            accept={acceptedFormats || "image/*"}
            style={{ display: "none" }}
            onChange={(e) => handleAddAttachment(e)}
          />

          {(attachments?.length || 0) < (maxFiles || 0) && (
            <AddButton
              data-testid={testId.buttonAddNew}
              onClick={() => refAddDocumentImg.current?.click()}
            />
          )}
        </Tooltip>
      </FilesContainer>
      {!showFilename && <div className="type-name">{typeName}</div>}
      <Modal
        open={filePreview.open}
        onCancel={handleOnClosePreviewFile}
        footer={null}
        width="80%"
      >
        <PreviewWrapper>
          {filePreview.open && renderFilePreview()}
        </PreviewWrapper>
      </Modal>
    </AttachmentsContainer>
  );
};

export default AttachmentsPractice;
