import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    setAppraiserError,
    setAppraiserStatus,
    setAppraiserSubnetData,
    setValidation,
} from "../../../../../redux/features/appraisersSlice";
import { RootState } from "../../../../../redux/store";
import styled from "styled-components";
import { ContentWrapper, GenericWrapper } from "../../Commons/Wrappers";
import { ButtonConfirm } from "../../../../Layout/Buttons";
import {
    SupplierNetworkCollaborator,
    useLazyGetAppraisalStudioCollaboratorsQuery,
} from "../../../../../redux/apiSpecifications/apiFesf";
import AppraiserDetailsSubnetCollaboratorForm from "./AppraiserDetailsSubnetCollaboratorForm";
import { useAuthorization } from "../../../../../hooks/useAuthorization";

const AppraiserSubnetContainer = styled(ContentWrapper)`
    overflow: auto;
    max-height: calc(100vh - 16em);
`;

const AppraiserSubnetContent = styled(GenericWrapper)`
    display: flex;
    width: auto;
    flex-direction: column;
    gap: 2em;
    overflow-x: auto;
    min-width: 600px;

    .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        .title {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
        }

        .row {
            padding-top: 1em;
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            justify-content: center;

            .button-wrapper {
                margin-top: 0 !important;
                flex: 1;
                display: flex;
                justify-content: flex-end !important;

                button {
                    font-size: 0.9em;
                    // width: 12em;
                }
            }
        }
    }

    .body {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2em;
    }
`;

interface IAppraiserDetailsSubnetProps {
    idAppraiser: number;
    forceReload?: number;
}

const AppraiserDetailsSubnet = ({
    idAppraiser,
    forceReload,
}: IAppraiserDetailsSubnetProps) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { getAuthorization } = useAuthorization();

    const appraiserData = useSelector((state: RootState) =>
        state.appraisers.appraisers.find((a) => a.id === idAppraiser)
    );

    const { subnetData: appraiserSubnetData, validationErrors } =
        appraiserData || {};

    const [getAppraisalStudioCollaborators] =
        useLazyGetAppraisalStudioCollaboratorsQuery();

    useEffect(() => {
        if (!idAppraiser) return;

        dispatch(
            setAppraiserStatus({
                id: appraiserData?.id!,
                status: "loading",
            })
        );

        (async () => {
            const response = await getAppraisalStudioCollaborators({
                authorization: await getAuthorization(),
                id: idAppraiser,
            });

            if (response.isSuccess && response.data) {
                const data = response.data as SupplierNetworkCollaborator[];

                dispatch(
                    setAppraiserSubnetData({
                        id: appraiserData?.id!,
                        data: data,
                        isUpdate: false,
                    })
                );
            } else {
                dispatch(
                    setAppraiserError({
                        id: idAppraiser || 0,
                        message: "Errore caricamento sottorete perito.",
                    })
                );
            }
        })();
    }, [idAppraiser, forceReload]);

    const validateData = (collaborators: SupplierNetworkCollaborator[]) => {
        let isValid = true;

        for (const collaborator of collaborators || []) {
            if (
                !collaborator.membershipNumber ||
                collaborator.membershipNumber?.trim() === "" ||
                !collaborator.denomination ||
                collaborator.denomination?.trim() === ""
            ) {
                isValid = false;
            }
        }

        dispatch(
            setValidation({
                id: appraiserData?.id!,
                validationErrors: {
                    subnet: isValid ? [] : ["invalid data"],
                },
            })
        );
    };

    const handleOnAddNewCollaborator = () => {
        const newCollaborator: SupplierNetworkCollaborator = {};

        const updatedAppraiserSubnetData: SupplierNetworkCollaborator[] = [
            ...appraiserSubnetData!,
            newCollaborator,
        ];

        dispatch(
            setAppraiserSubnetData({
                id: idAppraiser!,
                data: updatedAppraiserSubnetData,
                isUpdate: true,
            })
        );

        validateData(updatedAppraiserSubnetData);
    };

    const handleOnDeleteCollaborator = (index: number) => {
        const updatedAppraiserSubnetData: SupplierNetworkCollaborator[] =
            appraiserSubnetData!.filter((_, i) => i !== index);

        dispatch(
            setAppraiserSubnetData({
                id: idAppraiser!,
                data: updatedAppraiserSubnetData,
                isUpdate: true,
            })
        );

        validateData(updatedAppraiserSubnetData);
    };

    const handleOnChange = (value: any, field: string, index: number) => {
        const updatedAppraiserSubnetData: SupplierNetworkCollaborator[] =
            appraiserSubnetData!.map((collaborator, i) => {
                if (i !== index) return collaborator;

                return {
                    ...collaborator,
                    [field]: value,
                };
            });

        dispatch(
            setAppraiserSubnetData({
                id: idAppraiser!,
                data: updatedAppraiserSubnetData,
                isUpdate: true,
            })
        );

        validateData(updatedAppraiserSubnetData);
    };

    return (
        <AppraiserSubnetContainer>
            <AppraiserSubnetContent>
                <div className="header">
                    <div className="title">
                        {t("edit-appraiser-subnet-title")}
                    </div>

                    <div className="row">
                        <div className="button-wrapper">
                            <ButtonConfirm
                                size="small"
                                onClick={handleOnAddNewCollaborator}
                                disabled={
                                    (validationErrors?.subnet?.length || 0) > 0
                                }
                            >
                                {
                                    t(
                                        "edit-appraiser-subnet-add-collaborator-button"
                                    )!
                                }
                            </ButtonConfirm>
                        </div>
                    </div>
                </div>

                <div className="body">
                    {appraiserSubnetData?.map((collaborator, index) => {
                        return (
                            <AppraiserDetailsSubnetCollaboratorForm
                                key={index}
                                collaborator={collaborator}
                                onChange={(val, field) =>
                                    handleOnChange(val, field, index)
                                }
                                onDelete={() =>
                                    handleOnDeleteCollaborator(index)
                                }
                            />
                        );
                    })}
                </div>
            </AppraiserSubnetContent>
        </AppraiserSubnetContainer>
    );
};

export default AppraiserDetailsSubnet;
